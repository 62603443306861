body {
  margin: 0;
  color: "#0AFF00";
  padding: 0;
  box-sizing: border-box;
  background-color: black;
  /* overflow: hidden; */
}


