.projectImages {
  position: sticky;
  object-fit: cover;
}
.PosterAustellung {
  width: 70%;
  height: auto;
}

.Installation {
  width: 90%;
  height: auto;
}

.InstaMobile {
  width: 10%;
  height: auto;
}

.OpenBook {
  width: 100%;
  height: auto;
}

.Cover {
  width: 100%;
  height: auto;
}

.Installation_02 {
  width: 60%;
  height: auto;
}

@font-face {
  font-family: "Arial Black";
  src: local("Arial Black"), url("../../font/Arial\ Black.ttf") format("woff2"),
  url("../../font/Arial\ Black.ttf") format("woff"),
  url("../../font/Arial\ Black.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

